















































import Vue from 'vue';
import infiniteScroll from '@/directives/infiniteScroll';
import { mergeClear } from '@/api/projectDetail';
export default Vue.extend({
  name: 'initCheck',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    checkResultList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    close() {
      const sequenceNbr = this.$route.query.sequenceNbr as string;
      mergeClear(sequenceNbr).then((res) => {
        console.log('res', res);
      });
      this.$emit('update:visible', false);
    },
    continueCombined() {
      this.$emit('update:visible', false);
      this.$emit('continueCombined');
    },
  },
  directives: {
    infiniteScroll,
  },
});
