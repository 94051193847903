/*
 * @Descripttion: 滚动加载
 * @Author: renmingming
 * @Date: 2022-03-08 17:15:49
 * @LastEditors: renmingming
 * @LastEditTime: 2022-03-09 16:33:31
 */
import throttle from 'lodash/throttle';
const scope = 'InfiniteScroll';
const getContainer = (el: HTMLElement) => {
  let container = el;
  while (container) {
    if ([window, document, document.documentElement].includes(container)) {
      return window;
    }
    const { overflowY, overflow } = getComputedStyle(container);
    const regexp = /auto|scroll/;
    if (regexp.test(overflow) || regexp.test(overflowY)) {
      return container;
    }
    container = container.parentNode as HTMLElement;
  }
};
const defaultProps = {
  disabled: false,
  distance: 50,
  immediate: true,
  delay: 200,
};
type optionsType = {
  disabled: boolean;
  distance: number;
  immediate: boolean;
  delay: number;
};
const getOptions = (el, vm): optionsType => {
  return Object.entries(defaultProps).reduce((options, [key, val]) => {
    const attr = el.getAttribute(`infinite-scroll-${key}`);
    options[key] = vm[attr] ?? val;
    return options;
  }, {}) as optionsType;
};

function scrollHandler(this: HTMLElement, el, load) {
  const { vm, observer } = el[scope];
  const { disabled, distance } = getOptions(el, vm);
  if (disabled) return;
  const _this = this as HTMLElement;
  const loadHeight = _this.offsetHeight + distance + _this.scrollTop;
  const scrollHeight = _this.scrollHeight;
  if (scrollHeight <= loadHeight) {
    load();
  }
  if (scrollHeight > loadHeight && observer) {
    observer.disconnect();
    el[scope].observer = null;
  }
}
export default {
  name: scope,
  inserted(el, binding, vnode) {
    const container = getContainer(el) as HTMLElement;
    const vm = vnode.context;
    const { immediate, delay } = getOptions(el, vm);
    const onScroll = throttle(scrollHandler, delay).bind(
      container,
      el,
      binding.value
    );
    el[scope] = { container, onScroll, vm };
    // 如果传入immediate，首次会先将内容加载至充满"容器"
    if (immediate) {
      const observer = (el[scope].observer = new MutationObserver(onScroll));
      // 监听子节点的变化
      observer.observe(container, {
        childList: true,
        subtree: true,
      });
      // 先手动加载一次数据
      onScroll();
    }
    container.addEventListener('scroll', onScroll);
  },
  unbind(el) {
    const { container, onScroll } = el[scope];
    if (container) {
      container.removeEventListener('scroll', onScroll);
    }
  },
};
